@import 'nprogress/nprogress.css';
@import 'viewerjs/dist/viewer.css';
@import './pop.less';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: var(--color-bg-1);
}

.search-form-wrapper {
  display: flex;
  transition: all 0.3s ease;
  &.hide {
    opacity: 0;
    height: 0;
  }
  &.show {
    opacity: 1;
    height: auto;
  }
  .search-form {
    align-items: baseline;
    &.arco-form-inline {
      .arco-form-item {
        margin-bottom: 20px;
      }
    }
  }
  .right-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    margin-bottom: 20px;
    border-left: 1px solid var(--color-border-2);
    box-sizing: border-box;
  }
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-form {
  padding-right: 20px;

  .arco-form-label-item-left {
    flex-shrink: 0 !important;
    > label {
      white-space: nowrap;
    }
  }
}

.whole-card-content {
  min-height: calc(100vh - 100px);
  overflow: auto;
}

.page-header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 15px;
  color: var(--color-text-1);
}

.globalDeleteModal {
  .arco-modal-header {
    .arco-modal-title {
      text-align: left !important;
    }
  }
  .arco-modal-footer {
    text-align: right !important;
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 4px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 7px;
  background-color: var(--color-text-4);
}

.viewer-backdrop {
  background-color: rgba(0, 0, 0, 0.9);
}

.arco-tree-select-multiple,
.arco-select-multiple {
  vertical-align: baseline !important;
}

.typeIconItem {
  font-size: 30px;
  margin: 0 16px 16px 0;
  cursor: pointer;
  &:nth-child(7n) {
    margin-right: 0;
  }
}
