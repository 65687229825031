.navbar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-border);
  box-sizing: border-box;
  background-color: var(--color-bg-2);
  height: 100%;
}

.left {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
  img {
    height: 35px;
    border-radius: 6px;
  }
}

.logo-name {
  color: var(--color-text-1);
  font-weight: 600;
  font-size: 18px;
  margin-left: 10px;
  font-family: 'PingFang SC';
}

.dropdown-icon {
  margin-right: 8px;
  font-size: 16px;
  vertical-align: text-bottom;
}

.right {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 25px;
  justify-content: flex-end;
  font-family: 'PingFang SC';
  color: var(--color-text-1);
  font-weight: 600;
  font-size: 14px;
  .userAvatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  li {
    padding: 0 0 0 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
