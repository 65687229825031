.popModalWrapper {
  width: 330px;
  height: 600px;
  border: 1px solid var(--color-border);
  .arco-card-body {
    height: 100%;
  }
  .searchInput {
    margin-bottom: 10px;
  }
  .tagScrollWrapper {
    height: calc(100% - 84px);
    overflow: auto;
    &.hasButtonGroup {
      height: calc(100% - 126px);
    }
  }
  .typeWrapper {
    margin-bottom: 8px;
    .subTitle {
      font-size: 12px;
      color: var(--color-neutral-10);
      font-weight: 600;
      line-height: 1.5715;
      margin-bottom: 6px;
    }
    .tagItem {
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
      background: var(--color-fill-2);
    }
  }

  .addWrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
  }
}
