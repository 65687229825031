.container {
  height: 100vh;
  background: radial-gradient(at center, #1890ff, #1874ff);
  .content {
    display: flex;
    height: 50vh;
    align-items: center;
    justify-content: center;
    z-index: 100;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    box-shadow: 0 0 20px 0 rgb(115 135 169 / 70%);
    border-radius: 4px;
    background-color: var(--color-bg-1);
    .contentImg {
      height: 100%;
    }
    .login-form {
      &-wrapper {
        width: 360px;
        padding: 50px;
        border-radius: 4px;
      }

      &-title {
        font-size: 24px;
        font-weight: 500;
        color: var(--color-text-1);
        line-height: 32px;
      }

      &-sub-title {
        font-size: 16px;
        line-height: 24px;
        color: var(--color-text-3);
      }

      &-error-msg {
        height: 32px;
        line-height: 32px;
        color: rgb(var(--red-6));
      }

      &-password-actions {
        display: flex;
        justify-content: space-between;
      }

      &-register-btn {
        color: var(--color-text-3) !important;
      }
    }
  }
}

.logo {
  position: fixed;
  top: 24px;
  left: 22px;
  display: inline-flex;
  align-items: center;
  z-index: 1;
  img {
    height: 35px;
  }
  &-text {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    margin-left: 10px;
    font-family: 'PingFang SC';
  }
}

.particle {
  width: 100%;
  height: 100%;
}

.rounder1 {
  position: fixed;
  top: -200px;
  right: -50px;
  width: 600px;
  height: 600px;
  background: #1884fffb;
  border-radius: 50%;
}
.rounder2 {
  position: fixed;
  bottom: -300px;
  left: 50%;
  width: 400px;
  height: 400px;
  background: #1884fffb;
  border-radius: 50%;
}

.rounder3 {
  position: fixed;
  top: 40%;
  right: 40%;
  width: 350px;
  height: 350px;
  background: #1890ffb0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
